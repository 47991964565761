<template>
  <ProfileField name="Работаю с запросами">
    <AtomInput
      type="textarea"
      name="С чем вы можете помочь пользователю"
      v-model="model"
      :error="error"
      desc="Перечислите по пунктам, какие запросы вы можете решить. Разделяйте пункты с помощью переноса строки."
    />
  </ProfileField>
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'
import ProfileField from '@/components/user/profile/ProfileField'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
const error = computed(
  () => Object.keys(props.errors)
    .filter(k => k.includes('requests'))
    .map(k => props.errors[k])
    .join('\n')
)
</script>

<style scoped lang="scss">

</style>
