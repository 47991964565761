<template>
  <ProfileField class="mentor-form__skills flex-mobile-separator">
    <ProfileRemovable class="inputs-grid" v-for="(skill, i) in model" :key="skill.id"
                      :removable="i < model.length - 1" @remove="removeSkill(i)">
      <SkillAtomInput
        name="Скилл"
        :options="skills"
        :selected="model"
        v-model:id="skill.skill_id"
        v-model:text="skill.skill_name"
        :error="errors[`skills.${i}.skill_id`] ?? errors[`skills.${i}.skill_name`] ?? (i === 0 && errors.skills)"
        :can-deselect="false"
        @new="newSkill"
      />
      <AtomInput
        type="select"
        name="Уровень"
        :options="skillLevelOptions"
        v-model="skill.level_id"
        :error="errors[`skills.${i}.level_id`]"
      />
    </ProfileRemovable>
  </ProfileField>
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'
import ProfileRemovable from '@/components/user/profile/forms/ProfileRemovable'
import ProfileField from '@/components/user/profile/ProfileField'
import { getSkillLevels, getSkills } from '@/api/skills'
import SkillAtomInput from '@/components/common/SkillAtomInput'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
const { data: skills } = await useLazyAsyncData(getSkills, { default: () => [], transform: d => d.data })
const { data: skillLevels } = await useLazyAsyncData(getSkillLevels, { default: () => [] })
const skillLevelOptions = computed(() => skillLevels.value.map(l => ({ id: l.id, name: l.title })))

watch(model, arr => {
  if (!Array.isArray(arr)) return

  arr.filter(s => !!s.skill_name).forEach(s => newSkill(s.skill_name))

  const last = arr[arr.length - 1]
  if (!last || last.skill_id || last.skill_name) model.value.push({ id: (last?.id ?? 0) + 1 })
}, { immediate: true, deep: true })

function removeSkill (i) {
  model.value.splice(i, 1)
}

function newSkill (name) {
  if (skills.value.some(s => s.name === name)) return
  skills.value.push({ id: name, name })
}
</script>

<style scoped lang="scss">

</style>
