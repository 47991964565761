<template>
  <ProfileField name="Место работы" v-for="(project, i) in model" :key="project.id" v-bind="$attrs"
                :removable="model.length > 1" @remove="remove(model, i)" class="inputs-grid">
    <AtomInput
      name="Название компании или проекта"
      v-model="project.name"
      :error="errors[`projects.${i}.name`]"
    />
    <AtomInput
      name="Должность"
      v-model="project.position"
      :error="errors[`projects.${i}.position`]"
    />
    <AtomInput
      type="datepicker"
      datepicker-type="month"
      name="Начал работать"
      v-model="project.date_from"
      :error="errors[`projects.${i}.date_from`]"
    />
    <AtomInput
      type="datepicker"
      datepicker-type="month"
      name="Завершил работу"
      v-model="project.date_to"
      :error="errors[`projects.${i}.date_to`]"
      :disabled="project.still_working"
    />
    <div class="filler" />
    <AtomInput
      type="checkbox"
      name="По сей день"
      v-model="project.still_working"
      @change="toggleStillWorking(project)"
    />
    <AtomInput
      class="input-col-2"
      name="Чем вы занимались"
      v-model="project.description"
      :error="errors[`projects.${i}.description`]"
    />
  </ProfileField>
  <ProfileFormAdd @click="addProject" v-bind="$attrs">Добавить место работы</ProfileFormAdd>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import ProfileFormAdd from '@/components/user/profile/forms/ProfileFormAdd'
import AtomInput from '@/components/common/AtomInput'
import ProfileField from '@/components/user/profile/ProfileField'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

let projectsCounter = 1

watch(model, () => {
  if (model.value.length === 0) {
    model.value.push({ id: projectsCounter++ })
  } else {
    projectsCounter = model.value[model.value.length - 1].id + 1
  }
}, { immediate: true })

function addProject () {
  model.value.push({ id: projectsCounter++ })
}

function remove (data, i) {
  data.splice(i, 1)
}

function toggleStillWorking (obj) {
  if (!obj.still_working) delete obj.date_to
}
</script>

<style scoped lang="scss">

</style>
