<template>
  <AtomInput
    name="Стоимость занятий в час"
    symbol="₽"
    desc="Ровно столько вы получите на карту"
    :model-value="model"
    @update:model-value="updatePrice"
    v-bind="$attrs"
    :error="error"
    :process-field="processField"
  />
  <AtomInput
    name="Цена для менти"
    symbol="₽"
    desc="Цена с учетом комиссии платформы. Столько будет видеть менти в каталоге."
    :model-value="mentiPrice"
    @update:model-value="updateMentiPrice"
    v-bind="$attrs"
    :process-field="processField"
  />
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'

const props = defineProps(['modelValue', 'error'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const calculateMentiPrice = price => !isNaN(+price) ? Math.round(price * 100 / 75) : ''

const mentiPrice = ref(calculateMentiPrice(model.value))

function updatePrice (value) {
  model.value = value
  mentiPrice.value = calculateMentiPrice(value)
}
function updateMentiPrice (value) {
  model.value = Math.round(value * 75 / 100)
  mentiPrice.value = value
}

const processField = value => {
  return value === ''
    ? value
    : Math.min(100000, +value.toString().replace(/\D/g, ''))
}
</script>

<style scoped lang="scss">
.profile-price-form {
  &__helper .profile-price__title {
    color: var(--color-elements-primary);
  }
  &__hint {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 2px;
    color: var(--color-elements-secondary);
  }
}
</style>
