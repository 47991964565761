<template>
  <ProfileField name="Контакты" class="inputs-grid">
    <AtomInput
      name="Telegram"
      v-model="contacts.tg"
      :error="errors['contacts.tg'] ?? errors.contacts"
      @change="validateTelegram"
    >
      <template #symbol><IconTelegram class="icon" /></template>
    </AtomInput>
    <AtomInput
      name="VK"
      v-model="contacts.vk"
      :error="errors['contacts.vk']"
    >
      <template #symbol><IconVk class="icon" /></template>
    </AtomInput>
    <AtomInput
      name="Linked In"
      v-model="contacts.in"
      :error="errors['contacts.in']"
    >
      <template #symbol><IconLinkedIn class="icon" /></template>
    </AtomInput>
    <AtomInput
      name="Другое"
      v-model="contacts.other"
      :error="errors['contacts.other']"
    >
      <template #symbol><IconLink class="icon" /></template>
    </AtomInput>
  </ProfileField>
</template>

<script setup>
import { IconLink } from '@tabler/icons-vue'

const props = defineProps({
  modelValue: Object,
  errors: Object
})
const emit = defineEmits(['update:modelValue'])

const contacts = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const toTelegramLink = str => {
  if (!str) return str

  let result = str
    .replaceAll(' ', '')
    .replace(/^https?:\/\//i, '')
    .replace('t.me/', '')

  const phone = result.replaceAll(/[^0-9+]/g, '')
    .match(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/)
  if (phone) result = (['8', '7', ''].includes(phone[1]) ? '+7' : phone[1]) + phone[0].slice(phone[1].length)
  else if (result.charAt(0) === '@') result = result.slice(1)

  return `https://t.me/${result}`
}
const validateTelegram = () => contacts.value.tg = toTelegramLink(contacts.value.tg)
</script>

<style scoped lang="scss">

</style>
