<template>
  <ProfileField name="Учебное заведение" v-for="(education, i) in model" :key="education.id" v-bind="$attrs"
                :removable="model.length > 1" @remove="removeEducation(i)" class="inputs-grid">
    <AtomInput
      name="Заведение"
      v-model="education.organization"
      :error="errors[`educations.${i}.organization`]"
    />
    <AtomInput
      name="Специальность"
      v-model="education.direction"
      :error="errors[`educations.${i}.direction`]"
    />
    <AtomInput
      type="datepicker"
      datepicker-type="year"
      name="Год окончания"
      v-model="education.year_to"
      :error="errors[`educations.${i}.year_to`]"
      :disabled="education.still_studying"
    />
    <AtomInput
      class="input-centered"
      type="checkbox"
      name="По сей день"
      v-model="education.still_studying"
    />
  </ProfileField>
  <ProfileFormAdd @click="addEducation" v-bind="$attrs">Добавить место учебы</ProfileFormAdd>
</template>

<script setup>
import ProfileFormAdd from '@/components/user/profile/forms/ProfileFormAdd'
import AtomInput from '@/components/common/AtomInput'
import ProfileField from '@/components/user/profile/ProfileField'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

let counter = 1

watch(model, () => {
  model.value.forEach(e => { e.still_studying = e.still_studying ?? !e.year_to })
  if (model.value.length === 0) {
    model.value.push({ id: counter++ })
  } else {
    counter = model.value[model.value.length - 1].id + 1
  }
}, { immediate: true })

function addEducation () {
  model.value.push({ id: counter++ })
}
function removeEducation (i) {
  model.value.splice(i, 1)
}
</script>

<style scoped lang="scss">

</style>
