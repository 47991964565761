<template>
  <div class="profile-section" :class="{ editing: editing && !isMobile, 'profile-section--custom': custom }">
    <ProfileFormHeader v-if="!custom" :editable="editable" :editing="editing" @edit="editing = true" @cancel="onCancel"
                       @submit="emit('submit')" :pending="pending" :subtitle="subtitle" :hint="hint" :edit-actions="false">{{ title }}</ProfileFormHeader>
    <div class="profile-section__body" v-bind="$attrs">
      <slot v-if="!editing || isMobile" v-bind="{ editable, editing: slotEditing, edit }" />
      <template v-else>
        <slot name="form" />
        <div class="profile-section__actions" :class="{ pending }">
          <BaseButton @click="emit('submit')">Сохранить</BaseButton>
          <BaseButton outlined @click="onCancel">Отмена</BaseButton>
        </div>
      </template>
    </div>
  </div>
  <Teleport to="body">
    <MobilePopup :shown="isMobile && editing">
      <MobilePopupHeader :title="title" @close="onCancel" />
      <div class="horiz-container" v-bind="$attrs"><slot name="form" /></div>
      <Navbar>
        <BaseButton class="save-btn" :class="{ pending }" color-primary small @click="emit('submit')">Сохранить изменения</BaseButton>
      </Navbar>
    </MobilePopup>
  </Teleport>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import ProfileFormHeader from '@/components/user/profile/forms/ProfileFormHeader'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  subtitle: String,
  pending: Boolean,
  hint: String,
  custom: Boolean
})
const emit = defineEmits(['submit', 'cancel'])
const slots = useSlots()

const { isMobile } = useBreakpoints()

const profileEditable = inject('profile.editable')
const editable = computed(() => !!slots.form && profileEditable.value)
const editing = ref(false)

const edit = () => editable.value && (editing.value = true)
function onCancel () {
  editing.value = false
  emit('cancel')
}

const slotEditing = computed({
  get: () => editing.value && !isMobile.value,
  set: value => editing.value = value
})

defineExpose({ editable, editing, edit })
</script>

<style scoped lang="scss">
.profile-section {
  &:not(&--custom):not(:first-child) {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--color-separator);
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 19px;
  }
  &.editing {
    .profile-section__body {
      gap: 48px;
    }
  }
}

.horiz-container {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.save-btn {
  flex: 1;
}
</style>
