<template>
  <ProfileField name="Языки">
    <div class="mentor-form__hint">Выберите языки, на которых вы способны свободно общаться</div>
    <div class="chip-list">
      <Chip v-for="lang in availableLanguages" :key="lang.id" :selected="languages.has(lang.id)" @click="toggleLanguage(lang.id)">{{ lang.title }}</Chip>
    </div>
  </ProfileField>
</template>

<script setup>
import Chip from '@/components/common/Chip'
import ProfileField from '@/components/user/profile/ProfileField'
import { getLanguages } from '@/api/user'

const props = defineProps({
  modelValue: Set
})
const emit = defineEmits(['update:modelValue'])

const languages = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const { data: availableLanguages } = await useLazyAsyncData(getLanguages, { default: () => [] })

function toggleLanguage (id) {
  if (!languages.value.delete(id)) languages.value.add(id)
}
</script>

<style scoped lang="scss">

</style>
