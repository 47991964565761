<template>
  <ProfileField name="Мероприятие" v-for="(event, i) in model" :key="event.id" v-bind="$attrs"
                :removable="model.length > 1" @remove="remove(model, i)" class="inputs-grid">
    <AtomInput
      name="Мероприятие"
      v-model="event.name"
      :error="errors[`events.${i}.name`]"
    />
    <AtomInput
      type="datepicker"
      datepicker-type="year"
      name="Год участия"
      v-model="event.date_from"
      :error="errors[`events.${i}.date_from`]"
    />
    <AtomInput
      class="input-col-2"
      name="Роль участия"
      desc="Например: спикер, посетитель, организатор"
      v-model="event.role"
      :error="errors[`events.${i}.role`]"
    />
  </ProfileField>
  <ProfileFormAdd @click="addEvent" v-bind="$attrs">Добавить мероприятие</ProfileFormAdd>
</template>

<script setup>
import ProfileFormAdd from '@/components/user/profile/forms/ProfileFormAdd'
import AtomInput from '@/components/common/AtomInput'
import ProfileField from '@/components/user/profile/ProfileField'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

let counter = 1

watch(model, () => {
  if (model.value.length === 0) {
    model.value.push({ id: counter++ })
  } else {
    counter = model.value[model.value.length - 1].id + 1
  }
}, { immediate: true })

function addEvent () {
  model.value.push({ id: counter++ })
}

function remove (data, i) {
  data.splice(i, 1)
}
</script>

<style scoped lang="scss">

</style>
